<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal_form_tarifas_export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Generar Listado Tarifas</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="nombre" class="col-md-5">Nombre</label>
                    <input
                      type="text"
                      id="nombre"
                      v-model="filtros.nombre"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="tarifa" class="col-md-5">Tarifa</label>
                    <input
                      type="number"
                      id="tarifa"
                      v-model="filtros.tarifa"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="tipo_vehiculo_id" class="col-md-5"
                      >Tipo de vehículo</label
                    >
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="tipo_vehiculo"
                      placeholder="Tipo Vehículo"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.tipos_vehiculos"
                      :filterable="true"
                      @input="getSelectTiposVehiculos()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="ruta_id" class="col-md-5">Ruta</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="ruta"
                      placeholder="Ruta"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.rutas"
                      :filterable="true"
                      @input="getSelectRutas()"
                    ></v-select>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="nombre"
                              v-model="form.nombre"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="nombre"
                              >Nombre</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="producto_liquido_id"
                              v-model="form.producto_liquido_id"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="producto_liquido_id"
                              >Producto Liquido</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipo_vehiculo_id"
                              v-model="form.tipo_vehiculo_id"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tipo_vehiculo_id"
                              >Tipo de Vehículo</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="ruta_id"
                              v-model="form.ruta_id"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="ruta_id"
                              >Ruta</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tarifa"
                              v-model="form.tarifa"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="tarifa"
                              >Tarifa</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tiempo_cargue_sb"
                              v-model="form.tiempo_cargue_sb"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tiempo_cargue_sb"
                              >Tiempo de Cargue</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tiempo_descargue_sb"
                              v-model="form.tiempo_descargue_sb"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tiempo_descargue_sb"
                              >Tiempo de Descargue</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_ingreso"
                              v-model="form.fecha_ingreso"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="fecha_ingreso"
                              >Fecha de Ingreso</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_inicio_vigencia"
                              v-model="form.fecha_inicio_vigencia"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="fecha_inicio_vigencia"
                              >Fecha Inicio Vigencia</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_fin_vigencia"
                              v-model="form.fecha_fin_vigencia"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="fecha_fin_vigencia"
                              >Fecha Fin Vigencia</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="radicado"
                              v-model="form.radicado"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="radicado"
                              >Radicado</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="categoria"
                              v-model="form.categoria"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="categoria"
                              >Categoría</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="km"
                              v-model="form.km"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="km"
                              >Kilometraje</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipo_operacion"
                              v-model="form.tipo_operacion"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tipo_operacion"
                              >Tipo Operacion</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExce()"
                v-if="$store.getters.can('hidrocarburos.tarifas.export')"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "EmpresaExport", //llegada tambien
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      tipo_vehiculo: {},
      ruta: {},
      //SE CREAN LOS CHECKLIST PARA EL BACK
      form: {
        nombre: true,
        producto_liquido_id: true,
        tipo_vehiculo_id: true,
        ruta_id: true,
        tarifa: true,
        tiempo_cargue_sb: true,
        tiempo_descargue_sb: true,
        fecha_ingreso: true,
        fecha_inicio_vigencia: true,
        fecha_fin_vigencia: true,
        radicado: true,
        categoria: true,
        km: true,
        tipo_operacion: true,
      },
      //SE CREAN FILTROS PARA EL BACK
      filtros: {
        nombre: null,
        tarifa: null,
        tipo_vehiculo_id: null,
        ruta_id: null,
      },
      //OBTIENE LAS LISTAS DE LOS CORRESPONDIENTES A LAS EMPRESAS
      listasForms: {
        tipos_vehiculos: [],
        rutas: [],
      },
    };
  },

  methods: {
    /*     getTiposVehiculos() {
      axios.get("api/admin/tiposVehiculos/lista").then((response) => {
        this.listasForms.tipos_vehiculos = response.data;
      });
    }, */

    getTiposVehiculos() {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 2,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tipos_vehiculos = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getSelectTiposVehiculos() {
      this.filtros.tipo_vehiculo = {};
      this.filtros.tipo_vehiculo_id = null;
      if (this.tipo_vehiculo) {
        this.filtros.tipo_vehiculo = this.tipo_vehiculo;
        this.filtros.tipo_vehiculo_id = this.tipo_vehiculo.id;
      }
    },
    getRutas() {
      axios.get("api/admin/rutas/lista").then((response) => {
        this.listasForms.rutas = response.data;
      });
    },
    getSelectRutas() {
      this.filtros.ruta = {};
      this.filtros.ruta_id = null;
      if (this.ruta) {
        this.filtros.ruta = this.ruta;
        this.filtros.ruta_id = this.ruta.id;
      }
    },
    //MANDA A LLAMAR LA FUNCION EXPORT EN EL BACK
    generarListadoExce() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/tarifas/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    await this.getTiposVehiculos();
    await this.getRutas();
  },
};
</script>
